import { calciocomApi, calciocomApiFrontend } from "./axiosConfig"
import { LocalizedNews, NewsPaginationInput, NewsPost, NewsPut } from "../types/news"
import * as APIConstants from "../constants/apis"
import { PaginationOutput } from "../types/pagination"
import { Ids } from "../types/entity"
import { generatePath } from "react-router-dom"
import { omit } from "lodash"

function newsToFormData(newsPost: NewsPost | NewsPut) {
  const formData = new FormData()

  newsPost.image && formData.append("image", newsPost.image)
  formData.append("data", JSON.stringify(omit(newsPost, "image")))

  return formData
}

export const NewsAPI = {
  create: async function (newsPost: NewsPost): Promise<PaginationOutput<LocalizedNews>> {
    const response = await calciocomApiFrontend.request({
      method: APIConstants.POST,
      url: APIConstants.NEWS_V1_POST_CREATE,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: newsToFormData(newsPost),
    })

    return response.data.news
  },
  update: async function (newsPut: NewsPut): Promise<PaginationOutput<LocalizedNews>> {
    const response = await calciocomApiFrontend.request({
      method: APIConstants.PUT,
      url: APIConstants.NEWS_V1_PUT_UPDATE,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: newsToFormData(newsPut),
    })

    return response.data.news
  },
  getPaginated: async function (paginationInput: NewsPaginationInput): Promise<PaginationOutput<LocalizedNews>> {
    const response = await calciocomApi.request({
      method: APIConstants.POST,
      url: APIConstants.NEWS_V1_POST_PAGINATED,
      data: paginationInput,
    })

    return response.data.news
  },
  getPaginatedSimplified: async function (
    paginationInput: NewsPaginationInput,
  ): Promise<PaginationOutput<LocalizedNews>> {
    const response = await calciocomApi.request({
      method: APIConstants.POST,
      url: APIConstants.NEWS_V1_POST_PAGINATED_SIMPLIFIED,
      data: paginationInput,
    })

    return response.data.news
  },
  getNewsForHome: async function (): Promise<PaginationOutput<LocalizedNews>> {
    const response = await calciocomApi.request({
      method: APIConstants.GET,
      url: APIConstants.NEWS_V1_GET_HOME,
    })

    return response.data.news
  },
  getBackofficePaginated: async function (
    paginationInput: NewsPaginationInput,
  ): Promise<PaginationOutput<LocalizedNews>> {
    const response = await calciocomApiFrontend.request({
      method: APIConstants.POST,
      url: APIConstants.NEWS_V1_POST_BACKOFFICE_PAGINATED,
      data: paginationInput,
    })

    return response.data.news
  },
  getNewsIds: async function (calciocomSlug: string): Promise<Ids> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.NEWS_V1_GET_IDS_BY_CALCIO_COM_SLUG, {
        calciocomSlug: calciocomSlug,
      }),
      method: APIConstants.GET,
    })
    return response.data.ids
  },
  getRelatedNewsById: async function (id: number): Promise<LocalizedNews[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.NEWS_V1_GET_RELATED_BY_ID, {
        id: id,
      }),
      method: APIConstants.GET,
    })
    return response.data.news
  },
  getNewsByCalciocomSlug: async function (calciocomSlug: string, competitionSlug?: string): Promise<LocalizedNews> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.NEWS_V1_GET_BY_CALCIO_COM_SLUG, {
        calciocomSlug: calciocomSlug,
      }),
      params: {
        competitionSlug: competitionSlug,
      },
      method: APIConstants.GET,
    })
    return response.data.news
  },
  getNewsByCalciocomSlugPrediction: async function (
    calciocomSlug: string,
    competitionSlug?: string,
  ): Promise<LocalizedNews> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.NEWS_V1_GET_BY_CALCIO_COM_SLUG_PREDICTION, {
        calciocomSlug: calciocomSlug,
      }),
      params: {
        competitionSlug: competitionSlug,
      },
      method: APIConstants.GET,
    })
    return response.data.news
  },
  getNews: async function (id: number): Promise<LocalizedNews> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.NEWS_V1_GET_BY_ID, {
        id: id,
      }),
      method: APIConstants.GET,
    })
    return response.data.news
  },
  search: async function (search: string): Promise<LocalizedNews[]> {
    const response = await calciocomApi.request({
      url: APIConstants.NEWS_V1_SEARCH,
      method: APIConstants.GET,
      params: {
        search: search,
      },
    })
    return response.data.news
  },
  getByAuthorSlugPaginated: async function (
    authorCalciocomSlug: string,
    pageNum: number,
  ): Promise<PaginationOutput<LocalizedNews & { authorName: string }>> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.NEWS_V1_GET_BY_AUTHOR, {
        id: authorCalciocomSlug,
      }),
      data: {
        currentPage: pageNum,
        pageSize: 6,
      },
      method: APIConstants.GET,
    })
    return response.data.news
  },
}
