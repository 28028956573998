export const LANGUAGE_HEADER = "language"

export const V1 = "v1"
const API = "api"

const BASE_URL_V1 = `/${API}/${V1}`

const CALCIO_COM_SLUG = "calciocomSlug"

export const POST = "POST"
export const PUT = "PUT"
export const GET = "GET"
export const PATCH = "PATCH"
export const DELETE = "DELETE"

const AUTH = "auth"
export const AUTH_TOKEN_NAME = `token`
export const AUTH_V1_LOGIN = `${BASE_URL_V1}/${AUTH}/login`
export const AUTH_V1_VERIFY_TOKEN = `${BASE_URL_V1}/${AUTH}/verifyToken`

const CRAWL_URLS = "crawlUrls"
export const CRAWL_URLS_IS_IMPORTANT = `${BASE_URL_V1}/${CRAWL_URLS}/isImportant`
export const CRAWL_URLS_GET_IMPORTANT_LIST = `${BASE_URL_V1}/${CRAWL_URLS}/getImportantList`

const COMPETITIONS = "competitions"
export const COMPETITIONS_V1_GET_ALL = `${BASE_URL_V1}/${COMPETITIONS}`
export const COMPETITIONS_V1_SEARCH = `${BASE_URL_V1}/${COMPETITIONS}/search`
export const COMPETITIONS_V1_GET_BY_ID = `${BASE_URL_V1}/${COMPETITIONS}/:id`
export const COMPETITIONS_V1_GET_BY_ID_BACKOFFICE = `${BASE_URL_V1}/backoffice/${COMPETITIONS}/:id`
export const COMPETITIONS_V1_PUT_UPDATE = `${BASE_URL_V1}/${COMPETITIONS}`
export const COMPETITIONS_V1_POST_PAGINATED = `${BASE_URL_V1}/${COMPETITIONS}/getPaginated`
export const COMPETITIONS_V1_GET_SEASONS_BY_ID = `${BASE_URL_V1}/${COMPETITIONS}/:id/seasons`
export const COMPETITIONS_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${COMPETITIONS}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const COMPETITIONS_V1_GET_IDS_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${COMPETITIONS}/${CALCIO_COM_SLUG}/:calciocomSlug/getIds`
export const COMPETITIONS_V1_GET_TEAM_WINNERS_BY_ID = `${BASE_URL_V1}/${COMPETITIONS}/:id/team-winners`
export const COMPETITIONS_V1_GET_LAST_SEASON_DETAILS_BY_ID = `${BASE_URL_V1}/${COMPETITIONS}/:id/last-season-details`

const SEASONS = "seasons"
export const SEASONS_V1_GET_ALL = `${BASE_URL_V1}/${SEASONS}`
export const SEASONS_V1_GET_BY_ID = `${BASE_URL_V1}/${SEASONS}/:id`
export const SEASONS_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${SEASONS}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const SEASONS_V1_GET_IDS_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${SEASONS}/${CALCIO_COM_SLUG}/:calciocomSlug/getIds`

const COMPETITION_SEASONS = "competitionSeasons"
export const COMPETITION_SEASONS_V1_GET_ALL = `${BASE_URL_V1}/${COMPETITION_SEASONS}`
export const COMPETITION_SEASONS_V1_GET_BY_ID = `${BASE_URL_V1}/${COMPETITION_SEASONS}/:id`
export const COMPETITION_SEASONS_V1_GET_ID_BY_COMPETITION_ID_AND_SEASON_ID = `${BASE_URL_V1}/competitions/:competitionId/seasons/:seasonId/getCompetitionSeasonId`
export const COMPETITION_SEASONS_V1_GET_PLAYER_PARAMETER_STANDINGS_BY_ID = `${BASE_URL_V1}/${COMPETITION_SEASONS}/:id/getPlayerParameterStandings`
export const COMPETITION_SEASONS_V1_GET_TEAM_PARAMETER_STANDINGS_BY_ID = `${BASE_URL_V1}/${COMPETITION_SEASONS}/:id/getTeamParameterStandings`
export const COMPETITION_SEASONS_V1_GET_HOME_TEAM_PARAMETER_STANDINGS_BY_ID = `${BASE_URL_V1}/${COMPETITION_SEASONS}/:id/getHomeTeamParameterStandings`
export const COMPETITION_SEASONS_V1_GET_TEAMS_BY_ID = `${BASE_URL_V1}/${COMPETITION_SEASONS}/:id/teams`

const GROUPS = "groups"
export const GROUPS_V1_GET_BY_ID = `${BASE_URL_V1}/${GROUPS}/:id`
export const GROUPS_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${GROUPS}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const GROUPS_V1_GET_TEAM_STANDINGS_BY_ID = `${BASE_URL_V1}/${GROUPS}/:id/getTeamStandings`
export const GROUPS_V1_GET_IS_STANDINGS_AVAILABLE = `${BASE_URL_V1}/${GROUPS}/getIsStandingsAvailable/:id`
export const GROUPS_V1_GET_HOME_TEAM_STANDINGS_BY_ID = `${BASE_URL_V1}/${GROUPS}/:id/getHomeTeamStandings`
export const GROUPS_V1_GET_TEAM_BETTING_PARAMETER_STANDINGS_BY_ID = `${BASE_URL_V1}/${GROUPS}/:id/getTeamBettingParameterStandings`

const COUNTRIES = "countries"
export const COUNTRIES_V1_GET_ALL = `${BASE_URL_V1}/${COUNTRIES}`
export const COUNTRIES_V1_GET_BY_ID = `${BASE_URL_V1}/${COUNTRIES}/:id`
export const COUNTRIES_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${COUNTRIES}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const COUNTRIES_V1_GET_IDS_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${COUNTRIES}/${CALCIO_COM_SLUG}/:calciocomSlug/getIds`

const NEWS = "news"
export const NEWS_V1_POST_CREATE = `${BASE_URL_V1}/${NEWS}`
export const NEWS_V1_PUT_UPDATE = `${BASE_URL_V1}/${NEWS}`
export const NEWS_V1_POST_PAGINATED = `${BASE_URL_V1}/${NEWS}/getPaginated`
export const NEWS_V1_POST_PAGINATED_SIMPLIFIED = `${BASE_URL_V1}/${NEWS}/getPaginatedSimplified`
export const NEWS_V1_GET_HOME = `${BASE_URL_V1}/${NEWS}/home`
export const NEWS_V1_POST_BACKOFFICE_PAGINATED = `${BASE_URL_V1}/backoffice/${NEWS}/getPaginated`
export const NEWS_V1_GET_BY_ID = `${BASE_URL_V1}/${NEWS}/:id`
export const NEWS_V1_GET_RELATED_BY_ID = `${BASE_URL_V1}/${NEWS}/:id/related`
export const NEWS_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${NEWS}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const NEWS_V1_GET_BY_CALCIO_COM_SLUG_PREDICTION = `${BASE_URL_V1}/${NEWS}/prediction/${CALCIO_COM_SLUG}/:calciocomSlug`
export const NEWS_V1_GET_IDS_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${NEWS}/${CALCIO_COM_SLUG}/:calciocomSlug/getIds`
export const NEWS_V1_SEARCH = `${BASE_URL_V1}/${NEWS}/search`
export const NEWS_V1_GET_BY_AUTHOR = `${BASE_URL_V1}/${NEWS}/:id/getByAuthor`

const GUIDES = "guides"
export const GUIDES_V1_POST_CREATE = `${BASE_URL_V1}/${GUIDES}`
export const GUIDES_V1_PUT_UPDATE = `${BASE_URL_V1}/${GUIDES}`
export const GUIDES_V1_POST_PAGINATED = `${BASE_URL_V1}/${GUIDES}/getPaginated`
export const GUIDES_V1_POST_BACKOFFICE_PAGINATED = `${BASE_URL_V1}/backoffice/${GUIDES}/getPaginated`
export const GUIDES_V1_GET_BY_ID = `${BASE_URL_V1}/${GUIDES}/:id`
export const GUIDES_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${GUIDES}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const GUIDES_V1_GET_IDS_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${GUIDES}/${CALCIO_COM_SLUG}/:calciocomSlug/getIds`
export const GUIDES_V1_SEARCH = `${BASE_URL_V1}/${GUIDES}/search`

export const GUIDES_PAGE_SIZE = 8

const BOOKMAKER_BONUSES = "bookmakerBonuses"
export const BOOKMAKER_BONUSES_V1_POST_CREATE = `${BASE_URL_V1}/${BOOKMAKER_BONUSES}`
export const BOOKMAKER_BONUSES_V1_PUT_UPDATE = `${BASE_URL_V1}/${BOOKMAKER_BONUSES}`
export const BOOKMAKER_BONUSES_V1_GET_ALL = `${BASE_URL_V1}/${BOOKMAKER_BONUSES}`
export const BOOKMAKER_BONUSES_V1_POST_PAGINATED = `${BASE_URL_V1}/${BOOKMAKER_BONUSES}/getPaginated`
export const BOOKMAKER_BONUSES_V1_SEARCH = `${BASE_URL_V1}/${BOOKMAKER_BONUSES}/search`
export const BOOKMAKER_BONUSES_V1_GET_HOME = `${BASE_URL_V1}/${BOOKMAKER_BONUSES}/home`
export const BOOKMAKER_BONUSES_V1_GET_BY_ID = `${BASE_URL_V1}/${BOOKMAKER_BONUSES}/:id`
export const BOOKMAKER_BONUSES_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${BOOKMAKER_BONUSES}/${CALCIO_COM_SLUG}/:calciocomSlug`

const TEAMS = "teams"
export const TEAMS_V1_GET = `${BASE_URL_V1}/${TEAMS}`
export const TEAMS_V1_GET_SIMPLIFIED = `${BASE_URL_V1}/${TEAMS}/simplified`
export const TEAMS_V1_GET_COMPETITION_SEASONS = `${BASE_URL_V1}/${TEAMS}/:id/getCompetitionSeasons`
export const TEAMS_V1_GET_PLAYERS_BY_ID = `${BASE_URL_V1}/${TEAMS}/:id/getPlayers`
export const TEAMS_V1_NEXT_MATCHES = `${BASE_URL_V1}/${TEAMS}/:id/matches/next`
export const TEAMS_V1_GET_BY_ID = `${BASE_URL_V1}/${TEAMS}/:id`
export const TEAMS_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${TEAMS}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const TEAMS_V1_GET_IDS_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${TEAMS}/${CALCIO_COM_SLUG}/:calciocomSlug/getIds`
export const TEAMS_V1_GET_LAST_MATCHES_PLAYED = `${BASE_URL_V1}/${TEAMS}/:id/getLastMatchesPlayed`
export const TEAMS_V1_GET_BETTING_STATS = `${BASE_URL_V1}/${TEAMS}/:id/getBettingStats`
export const TEAMS_V1_GET_INDEX_STATS = `${BASE_URL_V1}/${TEAMS}/:id/getIndexStats`
export const TEAMS_V1_GET_COMPETITION_SEASON_STATS = `${BASE_URL_V1}/${TEAMS}/:id/getCompetitionSeasonStats`
export const TEAMS_V1_SEARCH = `${BASE_URL_V1}/${TEAMS}/search`
export const TEAMS_V1_GET_COMPARISON = `${BASE_URL_V1}/${TEAMS}/:id/getComparison`
export const TEAMS_V1_POST_PAGINATED = `${BASE_URL_V1}/${TEAMS}/getPaginated`
export const TEAMS_V1_GET_BY_ID_BACKOFFICE = `${BASE_URL_V1}/backoffice/${TEAMS}/:id`
export const TEAMS_V1_PUT_UPDATE = `${BASE_URL_V1}/${TEAMS}`

const MATCHES = "matches"
export const MATCHES_V1_GET_BY_ID = `${BASE_URL_V1}/${MATCHES}/:id`
export const MATCHES_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${MATCHES}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const MATCHES_V1_GET_IDS_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${MATCHES}/${CALCIO_COM_SLUG}/:calciocomSlug/getIds`
export const MATCHES_V1_GET = `${BASE_URL_V1}/${MATCHES}`
export const MATCHES_NEXT_V1_GET = `${BASE_URL_V1}/${MATCHES}/next`
export const MATCHES_V1_GET_HOME = `${BASE_URL_V1}/${MATCHES}/getHomeMatches`
export const MATCHES_V1_GET_REFEREES = `${BASE_URL_V1}/${MATCHES}/:id/referees`
export const MATCHES_V1_GET_STATS = `${BASE_URL_V1}/${MATCHES}/:id/getStats`
export const MATCHES_V1_GET_EVENTS = `${BASE_URL_V1}/${MATCHES}/:id/getEvents`
export const MATCHES_V1_GET_TEAM_PLAYERS = `${BASE_URL_V1}/${MATCHES}/:id/getTeamPlayers`
export const MATCHES_V1_GET_HOME_LIVE_MATCHES = `${BASE_URL_V1}/${MATCHES}/getHomeLiveMatches`
export const MATCHES_V1_GET_CLOSEST_DATE_WITH_MATCHES = `${BASE_URL_V1}/${MATCHES}/getClosestDateWithMatches`
export const MATCHES_V1_GET_NEXT_DATE_WITH_MATCHES = `${BASE_URL_V1}/${MATCHES}/getNextDateWithMatches`
export const MATCHES_V1_SEARCH = `${BASE_URL_V1}/${MATCHES}/search`
export const MATCHES_V1_POST_PREDICTIONS_GET_PAGINATED = `${BASE_URL_V1}/${MATCHES}/predictions/getPaginated`
export const MATCHES_V1_GET_PREDICTIONS_HOME = `${BASE_URL_V1}/${MATCHES}/predictions/home`
export const MATCHES_V1_GET_PREDICTION_BY_MATCH_ID = `${BASE_URL_V1}/${MATCHES}/:id/predictions`
export const TEAMS_V1_GET_COMPETITION_SEASON_PLAYERS_STATS = `${BASE_URL_V1}/${MATCHES}/stats/getTeamCompetitionSeasonPlayersStats/:id`

const STADIUMS = "stadiums"
export const STADIUMS_V1_GET = `${BASE_URL_V1}/${STADIUMS}`
export const STADIUMS_V1_GET_BY_ID = `${BASE_URL_V1}/${STADIUMS}/:id`
export const STADIUMS_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${STADIUMS}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const STADIUMS_V1_GET_IDS_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${STADIUMS}/${CALCIO_COM_SLUG}/:calciocomSlug/getIds`

const PLAYERS = "players"
export const PLAYERS_V1_GET_TEAMS_BY_ID = `${BASE_URL_V1}/${PLAYERS}/:id/getTeams`
export const PLAYERS_V1_GET_BY_ID = `${BASE_URL_V1}/${PLAYERS}/:id`
export const PLAYERS_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${PLAYERS}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const PLAYERS_V1_GET_IDS_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${PLAYERS}/${CALCIO_COM_SLUG}/:calciocomSlug/getIds`
export const PLAYERS_V1_GET_INDEX_STATS = `${BASE_URL_V1}/${PLAYERS}/:id/getIndexStats`
export const PLAYERS_V1_GET_COMPETITION_SEASON_STATS = `${BASE_URL_V1}/${PLAYERS}/:id/getCompetitionSeasonStats`
export const PLAYERS_V1_GET_RETIRED_STATS = `${BASE_URL_V1}/${PLAYERS}/:id/getRetiredStats`
export const PLAYERS_V1_GET_STANDINGS = `${BASE_URL_V1}/${PLAYERS}/getStandings`
export const PLAYERS_V1_GET_HOME_STANDINGS = `${BASE_URL_V1}/${PLAYERS}/getHomeStandings`
export const PLAYERS_V1_SEARCH = `${BASE_URL_V1}/${PLAYERS}/search`
export const PLAYERS_V1_GET_TEAM_COMPARISON = `${BASE_URL_V1}/${PLAYERS}/:id/getTeamComparison`
export const PLAYERS_V1_GET_REFEREE_COMPARISON = `${BASE_URL_V1}/${PLAYERS}/:id/getRefereeComparison`

const AUTHORS = "authors"
export const AUTHORS_V1_GET_NEWS_BY_ID = `${BASE_URL_V1}/${AUTHORS}/:id/getNews`
export const AUTHORS_V1_GET_BY_ID = `${BASE_URL_V1}/${AUTHORS}/:id`
export const AUTHORS_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${AUTHORS}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const AUTHORS_V1_SEARCH = `${BASE_URL_V1}/${AUTHORS}/search`

const COACHES = "coaches"
export const COACHES_V1_GET_BY_ID = `${BASE_URL_V1}/${COACHES}/:id`
export const COACHES_V1_GET_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${COACHES}/${CALCIO_COM_SLUG}/:calciocomSlug`
export const COACHES_V1_GET_IDS_BY_CALCIO_COM_SLUG = `${BASE_URL_V1}/${COACHES}/${CALCIO_COM_SLUG}/:calciocomSlug/getIds`

const FANTASTIC_PLAYERS = "fantasticPlayers"
export const FANTASTIC_PLAYERS_V1_GET = `${BASE_URL_V1}/${FANTASTIC_PLAYERS}`

const SITEMAPS = "sitemaps"
export const SITEMAPS_POST_POPULATE_SITEMAP_TABLE = `${BASE_URL_V1}/${SITEMAPS}/populateSitemapTable`
export const SITEMAPS_POST_GET_PAGINATED = `${BASE_URL_V1}/${SITEMAPS}/getPaginated`

const NEWS_SITEMAPS = "news-sitemaps"
export const NEWS_SITEMAPS_POST_POPULATE_SITEMAP_TABLE = `${BASE_URL_V1}/${NEWS_SITEMAPS}/populateSitemapTable`
export const NEWS_SITEMAPS_POST_GET_PAGINATED = `${BASE_URL_V1}/${NEWS_SITEMAPS}/getPaginated`

const REFEREES = "referees"
export const REFEREES_V1_GET_BY_ID = `${BASE_URL_V1}/${REFEREES}/:id`

const IMPORT_KAMA = "import-kama"
export const IMPORT_KAMA_V1_START = `${BASE_URL_V1}/${IMPORT_KAMA}/start`
export const IMPORT_KAMA_V1_STOP = `${BASE_URL_V1}/${IMPORT_KAMA}/stop`
export const IMPORT_KAMA_V1_STATUS = `${BASE_URL_V1}/${IMPORT_KAMA}/status`

export const CALCIO_COM_CONFIGS_V1_GET = `${BASE_URL_V1}/calciocomConfigs`
export const CALCIO_COM_CONFIGS_V1_GET_HOME_DATA = `${BASE_URL_V1}/calciocomConfigs/getHomeData`
export const CALCIO_COM_CONFIGS_V1_GET_BETTING_DATA = `${BASE_URL_V1}/calciocomConfigs/getBettingData`

export const CALCIO_COM_NEWSLETTER_V1_POST = `${BASE_URL_V1}/newsletter`
export const CALCIO_COM_NEWSLETTER_V1_POST_UPLOAD_TO_FTP_SERVER = `${BASE_URL_V1}/newsletter/uploadToFTPServer`

const PAYMENTS_METHODS = "paymentsMethods"
export const PAYMENTS_METHODS_V1_GET_ALL = `${BASE_URL_V1}/${PAYMENTS_METHODS}`

const BONUS_QUOTA = "bonusQuota"
export const BONUS_QUOTAS_V1_GET_ALL = `${BASE_URL_V1}/${BONUS_QUOTA}`
export const BONUS_QUOTAS_V1_CREATE = `${BASE_URL_V1}/${BONUS_QUOTA}`
export const BONUS_QUOTAS_V1_POST_BACKOFFICE_PAGINATED = `${BASE_URL_V1}/backoffice/${BONUS_QUOTA}/getPaginated`
export const BONUS_QUOTAS_V1_UPDATE = `${BASE_URL_V1}/${BONUS_QUOTA}`
export const BONUS_QUOTAS_V1_GET_BY_ID = `${BASE_URL_V1}/${BONUS_QUOTA}/:id`
export const BONUS_QUOTAS_V1_POST_PAGINATED = `${BASE_URL_V1}/${BONUS_QUOTA}/getPaginated`
